import React from 'react'
import { Link } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import useHome from '../hooks/useHome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LogoWhatsapp from '../images/whatsapp.png'

const Footer = () => {
  const data = useHome()
  const logos = data?.allStrapiHomeFjs?.nodes[0]?.footerFJS?.logos
  const PageLists = data?.allStrapiHomeFjs?.nodes[0]?.footerFJS?.PageLists
  const ExternalList = data?.allStrapiHomeFjs?.nodes[0]?.footerFJS?.ExternalList
  const buildingLocation =
    data?.allStrapiHomeFjs?.nodes[0]?.footerFJS?.buildingLocation
  const socialNetwork =
    data?.allStrapiHomeFjs?.nodes[0]?.footerFJS?.socialNetwork

  const pagesList = PageLists?.map((page, idx) => (
    <Link
      to={'/' + page.fjs_page.slug}
      className="d-block px-2 text-white"
      key={`pages-${idx}`}
    >
      {page.fjs_page.title}
    </Link>
  ))

  const externalLink = ExternalList?.map((link, idx) => {
    if (link.name === 'Whatsapp' || link.name === 'whatsapp') {
      // Contacto de Whats App con icono en el borde inferior derecho
      // NO se esta utilizando porque estaen uso emblue
      /*return (
        <div className="footer__icon-whatsapp" key={`link-${idx}`}>
          <a href={link.url} target="_blank" rel="noreferrer">
            <img src={LogoWhatsapp} alt="Whatsapp" />
          </a>
        </div>
      )*/
    } else {
      return (
        <a
          href={link.url}
          className="d-block px-2 text-white"
          key={`pages-${idx}`}
          target="_blank"
          rel="noreferrer"
        >
          {link.name}
        </a>
      )
    }
  })

  const logosItem = logos?.map((logo, idx) => {
    const logoImg = getImage(logo?.image?.localFile)
    if (logo.title.toLowerCase() === 'data fiscal') {
      return <GatsbyImage image={logoImg} alt={logo?.title} className="footer__data-fiscal" />
    } else {
      return (
        <a href={logo.url} key={`logos-${idx}`} target="_blank" rel="noreferrer" className="footer__logos">
          <GatsbyImage image={logoImg} alt={logo?.title} />
        </a>
      )
    }

  })

  const buildingItem = buildingLocation?.map((location, idx) => (
    <div
      className="h-100 footer__building-card col-12 col-md-3"
      key={`building-id${idx}`}
    >
      <div className="footer__building-card-item">
        <FontAwesomeIcon
          icon={['fas', 'map-marker-alt']}
          size="2x"
          className="mb-2 text-white building-icon"
        />
        <div className="text-center">
          <h5>{location.buildingName}</h5>
        </div>

        <p>{location.information}</p>

        <div className="mx-auto">

          <Link
            to={location.url}
            target="_blank"
         // style={{ textDecoration: 'none', color:'white'}}
          >
            <button className=" btn btn-sm btn-outline-light px-3 fit-content mt-auto rounded-pill text-uppercase eventuality-button-font-size font-weight-medium font-secondary text-white">
              <span> VER MAPA</span>
            </button>
          </Link>
        </div>
      </div>
    </div >
  ))

  const socialNetworks = socialNetwork?.map((item, idx) => {
    let icon = []
    let buttonStyle = ''

    if (item.url.includes('instagram')) {
      icon = ['fab', 'instagram']
      buttonStyle = 'instagram'
    } else if (item.url.includes('facebook')) {
      icon = ['fab', 'facebook-f']
      buttonStyle = 'facebook'
    } else if (item.url.includes('linkedin')) {
      icon = ['fab', 'linkedin-in']
      buttonStyle = 'linkedin'
    } else if (item.url.includes('twitter')) {
      icon = ['fab', 'twitter']
      buttonStyle = 'twitter'
    }

    return (
      <a
        key={`link-${idx}`}
        href={item.url}
        target="_blank"
        className={`btn btn-social m-1 btn-social-icon btn-${buttonStyle}`}
        rel="noreferrer"
        title={item.name}
      >
        <FontAwesomeIcon icon={icon} size="sm" />
      </a>
    )
  })

  return (
    <footer className="py-5 background-dark text-white">
      <div className="container footer">
        <div className="row">
          <div className="col-12 col-md-5 col-lg-3 d-flex mb-4 justify-content-center">
            <div className="d-flex flex-column align-items-center  justify-content-center">
              <div className="d-flex mb-2 pt-2">{socialNetworks}</div>
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-9 mb-4 footer__links-container">
            <div className="footer__links">
              {pagesList} {externalLink}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="h-100 row py-4 footer__container-building">
            {buildingItem}
          </div>
        </div>
        <div className="row">
          <div className="w-100 d-flex flex-column align-items-center align-item-md-start flex-md-row justify-content-md-between">
            {logosItem}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
