export default function SortTeachers(dataTeachers) {
  for (let i = 0; i < dataTeachers.length; i++) {
    let j
    j = dataTeachers.findIndex((t) => t.name == dataTeachers[i].name)
    if (j != -1) {
      if (!dataTeachers[j].role.includes(dataTeachers[i].role)) {
        dataTeachers[j].role += ' - ' + dataTeachers[i].role
      }
      dataTeachers.splice(i, 0)
    }
  }

  const dataTeachersOrdened = dataTeachers.sort(SortT)

  return dataTeachersOrdened
}

function SortT(x, y) {
  if (x.role.includes('DIRECTOR')) {
    if (x.role == y.role) {
      if (x.name <= y.name) {
        return -1
      } else if (x.name > y.name) {
        return 1
      }
    }
    return -1
  }
  if (x.role.includes('COORDINADOR')) {
    if (x.role == y.role) {
      if (x.name <= y.name) {
        return -1
      } else if (x.name > y.name) {
        return 1
      }
    } else if (y.role.includes('DIRECTOR')) {
      return 1
    }
    return -1
  }

  if (x.role < y.role) {
    return -1
  }
  if (x.role > y.role) {
    return 1
  }
  if (x.role == y.role) {
    if (x.name <= y.name) {
      return -1
    } else if (x.name > y.name) {
      return 1
    }
  }
  return 0
}
