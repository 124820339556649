import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import useTrainingTypes from '../hooks/useTrainingTypes'

const Card = ({ course, related, image, imageDefault, home }) => {

    const dataTrainingTypes = useTrainingTypes()
    const trainingTypes = dataTrainingTypes?.allStrapiTrainingType?.nodes
    const courseName = course?.name

    // function capitalize(courseName) {
    //   const lower = courseName.toLowerCase()
    //   return courseName.charAt(0).toUpperCase() + lower.slice(1)
    // }

    return (
        <div
        className={`mb-4 col-12 col-lg-3 col-md-6 ${
          (course.featured && home) || related ? 'hightlighted' : ''
        }`}
        key={`noveltiesNodesId-${course.id}`}
      >
        <Link to={'/' + course.slug} className="text-decoration-none">
          <div className="h-100 d-flex flex-column rounded shadow-sm position-relative">
            {(course?.tag && !related && !course.featured) && (
              <div className={`card-tag card-tag-${course.tag?.color}`}>
                {course.tag?.title}
              </div>
            )}
            {image ? (
              <GatsbyImage
                image={image}
                alt={course.name}
                className="news-image"
              />
            ) : (
              <GatsbyImage
                image={imageDefault}
                alt={course.name}
                className="news-image"
              />
            )}
            <div className="flex-grow-1 p-4 d-flex flex-column news-info">
              <h6>
                <small>{course.dateStartQuery}</small>
              </h6>
              <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">
                {course?.training_type?.title}
                {
                  trainingTypes.find(
                    (trainingType) =>
                      trainingType.strapiId == course.training_type
                  )?.title
                }
              </div>
              <h5 className="font-weight-bold course-title text-break my-3">
                {courseName}
              </h5>
              <Link
                to={'/' + course.slug}
                className={
                  'btn btn-sm btn-outline mt-auto fit-content text-uppercase rounded-pill px-4 font-secondary eventuality-button-font-size'
                }
              >
                Ver más
              </Link>
            </div>
          </div>
        </Link>
        
      </div>
    )
}

export default Card

Card.propTypes = {
    course: PropTypes.object,
    related: PropTypes.bool,
    image: PropTypes.object,
    imageDefault: PropTypes.object,
    home: PropTypes.bool
  }
  
