import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

const globalConfig = graphql`
  query GlobalScripts {
    allStrapiHomeFjs {
      nodes {
        scripts {
          enable
          id
          name
          source
        }
      }
    }
  }
`
const GlobalScripts = () => useStaticQuery(globalConfig)

export const scriptSpecialCases = (script = {}) => {
  const { name, source, id } = script
  //para los casos especiales
  const cases = {
    'google-analytics': (
      <Helmet>
        <script key={id}>{`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${source.match(/G-([\w\d]+)/g)}');`}</script>
      </Helmet>
    ),
    'facebook-pixel': (
      <Helmet>
        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${source}');
            fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img
            height="1"
            width="1"
            src={'https://www.facebook.com/tr?id=${source}&ev=PageView&noscript=1}'/>`}
        </noscript>
      </Helmet>
    ),
    'pixel-fjs': (
      <Helmet>
        <script>
          {`(function(d,t,u,s,c,f){f=function(m){m=new Date();return m.getFullYear()+''+(m.getMonth()+1)+''+m.getDate()+'T'+m.getHours()+''+m.getMinutes()+''+m.getSeconds()};
          u='https://widgets-static.embluemail.com/accounts/${source}/scripts/sw_12085.js?ts='+f();s=d.createElement(t);
          s.async=1;s.src=u;c=d.getElementsByTagName(t)[0];c.parentNode.insertBefore(s,c);})(document,'script');`}
        </script>
      </Helmet>
    )
  }

  return cases[name] || null
}

const useScripts = () => {
  const { allStrapiHomeFjs } = GlobalScripts()
  const { nodes } = allStrapiHomeFjs || {}

  const scripts = nodes[0].scripts

  return scripts
}

export default useScripts
