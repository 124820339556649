import React from 'react'
import { Link } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { Navbar, Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun } from '@fortawesome/free-solid-svg-icons'
import { faMoon } from '@fortawesome/free-regular-svg-icons'
import useHome from '../hooks/useHome'
import { useTheme } from '../context/themeContext'
import { FaSearch } from 'react-icons/fa'


const Header = () => {
  const data = useHome()
  const imageLight = data?.allStrapiHomeFjs?.nodes[0]?.logo?.imageLight
  const imageDark = data?.allStrapiHomeFjs?.nodes[0]?.logo?.imageDark
  const trainingTypes =
    data?.allStrapiHomeFjs?.nodes[0]?.navbar?.typesOfTraining?.training_types
  const externalLink = data?.allStrapiHomeFjs?.nodes[0]?.navbar?.ExternalLinks
  const Pages = data?.allStrapiHomeFjs?.nodes[0]?.navbar?.Pages
  const logoFJSLight = getImage(imageLight?.localFile)
  const logoFJSDark = getImage(imageDark?.localFile)

  const trainingItem = trainingTypes?.map((item, idx) => (
    <Link
      to={'/' + item.slug}
      className="nav-link text-uppercase nav-primary"
      activeClassName="activeLinks"
      key={idx}
    >
      {item.title}
    </Link>
  ))
  const { theme, toggleTheme } = useTheme()

  const externalLinks = externalLink?.map((item, index) => (
    <a
      href={item.url}
      className="nav-link text-uppercase nav-primary"
      key={index}
      target="_blank"
      rel="noreferrer"
    >
      {item.name}
    </a>
  ))

  return (
    <header className=" background-header">
      <Navbar expand="lg" className='pt-lg-4 pb-lg-3 primary-background'>
        <Navbar.Brand href="/" >
          <Link to="/">
            {theme === 'dark' && logoFJSDark ? (
              <GatsbyImage
                image={logoFJSDark}
                alt="FJS"
                className="img-fluid ml-2 header-logo"
              />
            ) : (
              <GatsbyImage
                image={logoFJSLight}
                alt="FJS"
                className="img-fluid ml-2 header-logo"
              />
            )}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto pr-0 border-0"/>
          {/* <Navbar.Collapse id="basic-navbar-nav" className='w-100 d-flex flex-column flex-lg-row  justify-content-lg-between'> */}
          <Navbar.Collapse id="basic-navbar-nav" className='w-100 justify-content-lg-between'>
            <Nav className="mr-auto mr-lg-0 ml-lg-auto font-secondary font-weight-medium">
              {trainingItem}
              {Pages?.map((page, idx) => (
                <Link
                  to={'/' + page.fjs_page.slug}
                  className="nav-link text-uppercase nav-primary"
                  activeClassName="activeLinks"
                  key={idx}
                >
                  {page.fjs_page?.title}
                </Link>
              ))}
              {externalLinks}
            </Nav>
            <a href="/oferta-academica" className='mx-lg-3 my-2 my-lg-0'><FaSearch color='#666666' size={20}/></a>
            <button
                onClick={toggleTheme}
                className="text-uppercase nav-primary theme-toggle p-0 m-0 mb-lg-1"
              >
                {theme === 'dark' ? (
                  <FontAwesomeIcon icon={faMoon} size="xl"/>
                ) : (
                  <FontAwesomeIcon icon={faSun} size="xl"/>
                )}
              </button>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
