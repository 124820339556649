import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '../context/themeContext'

const LogosSection = ({ images, title }) => {
  const { theme } = useTheme()
  const logosList = images.map((logo, idx) => {
    return (
      <div key={`logo-${idx}`} className="h-100 mt-2 m-3 logo-image">
        {theme === 'dark' && logo?.imageDark ? (
          <img src={logo?.imageDark?.url} alt={title}/>
        ) : (
          <img src={logo?.imageLight?.url} alt={title}/>
        )}
      </div>
    )
  })

  return (
    <div className="container py-5">
      <div className="mb-5 p-5">
        <div className="d-flex align-items-center justify-content-center mb-3">
          <h2 className={'text-uppercase font-weight-extra-bold mb-3 ucc-text-blue'}>
            {title}
          </h2>
        </div>
        <div className="w-80 d-flex flex-row flex-wrap m-auto justify-content-center align-items-center">
          {logosList}
        </div>
      </div>
    </div>
  )
}

LogosSection.propTypes = {
  images: PropTypes.array,
  title: PropTypes.string
}

export default LogosSection
