import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'

const TextBlock = ({ title, content }) => {
  return (
    <div className="container mb-5">
      {title && (
        <h2 className="text-uppercase font-weight-extra-bold mb-3 ucc-text-blue ">
          {title}
        </h2>
      )}
      <ReactMarkdown
        // eslint-disable-next-line react/no-children-prop
        children={content}
        className="publication-content-markdown primary-grey-font"
        linkTarget="_blank"
      />
    </div>
  )
}

TextBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object
}

export default TextBlock
