import React from 'react'
import PropTypes from 'prop-types'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import ButtonInscription from './ButtonInscription'

const SimpleBanner = ({
  image,
  text,
  link,
  linkContacto,
  category,
  date,
  dateEndInscr,
  icon,
  imageDefault
}) => {
  const banner = getImage(image?.localFile)
  const iconBanner = getImage(icon?.localFile)
  const imgDefault = getImage(imageDefault?.localFile)

  return (
    <div className="slide slide__Item">
      <BgImage image={banner ? banner : imgDefault} className="slide__bgImage">
        <div className="slide__content" style={{ minHeight: 465 }}>
          {category && <p>{category}</p>}
          {icon && <GatsbyImage image={iconBanner} />}
          {text && (
            <h2 className="font-weight-extra-bold">{text}</h2>
          )}
          {date && <p>{date}</p>}
          <ButtonInscription
                dateEndInscr={dateEndInscr} link={link} linkContacto={linkContacto}
              />
        </div>
      </BgImage>
    </div>
  )
}

export default SimpleBanner

SimpleBanner.propTypes = {
  image: PropTypes.object,
  text: PropTypes.string,
  link: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string,
  dateEndInscr: PropTypes.string,
  icon: PropTypes.object,
  imageDefault: PropTypes.object
}
