import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import '../css/_searchBar.scss'

const SearchBar = ({ onQuery, placeholder }) => {

  const [textInput, setTextInput] = useState('')

  const handleInputChange = txt => {
    setTextInput(txt.target.value)

    // if (txt.target.value.length >= 3 || txt.target.value === "") {
    //   onQuery(txt.target.value)
    // }
  }

  const handlePressKey = (e) => {
    if (((e.charCode === 13) && (textInput.length >= 3)) || (e.charCode === 13 && textInput.length === 0)) {
      onQuery(textInput, e)
    }
  }

  const handleClick = (e) => {
    onQuery(textInput, e)
  }
  return (
    <div className='searchBar p-5'>
      <h2>Buscá tu curso</h2>
      <div className="inputContainer p-md-5">
        <input
          type="text"
          placeholder={placeholder}
          value={textInput}
          onChange={handleInputChange}
          onKeyPress={handlePressKey}
        />
        <button onClick={handleClick}>
          <FaSearch />
        </button>
      </div>

    </div>
  )
}

export default SearchBar