import { useStaticQuery, graphql } from 'gatsby'

const useGlobalConfig = () => {
  const query = useStaticQuery(graphql`
    {
      strapiCourseFjsTemplate {
        certificate {
          content
        }
        defaultCourseImg {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        form {
          title
          form {
            id
          }
        }
        info
        relatedCourses {
          title
        }
        cardAgreements {
          agreement {
            bank
            card
            fee
            id
            interest
            validity
          }
        }
        agreementsPage {
          name
          fjs_page {
            slug
          }
        }
      }
    }
  `)
  return query
}

export default useGlobalConfig
