import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './fontAwesome'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'

export const TabsContent = ({ data, title }) => {
  function CustomToggle({ children, eventKey }) {
    const [activeStates, setActiveState] = useState(false)
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      setActiveState(!activeStates)
    })
    let classActive = activeStates ? 'active' : ''
    return (
      <button
        type="button"
        className={`btn ${classActive}`}
        onClick={decoratedOnClick}
      >
        {children}
        <FontAwesomeIcon icon={['fas', 'chevron-down']} size="sm" />
      </button>
    )
  }
  const tabs = data?.map((item) => (
      <Accordion defaultActiveKey="0" key={item.id}>
        {item?.itemsAccordion?.map((accordion) => (
          <Card key={accordion.id}>
            <Card.Header>
              <CustomToggle eventKey={accordion.id}>
                <p className="mb-0 text-left" >
                  {accordion.title}
                </p>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={accordion.id}>
              <Card.Body>
                <ReactMarkdown
                  // eslint-disable-next-line react/no-children-prop
                  children={accordion.content}
                  className="publication-content-markdown"
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
  ))
  return (
    <div className="tabs-component pb-5">
      <h2 className="text-uppercase font-weight-extra-bold ucc-text-blue">
        {title}
      </h2>
      <div className="py-4 tabs-text">
          {tabs}
      </div>
    </div>
  )
}

TabsContent.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  eventKey: PropTypes.number.isRequired
}
