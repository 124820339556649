import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import { useForm } from '../hooks/useForm'
import {
  validateForm,
  validateEmail,
  validatePhoneNumber
} from '../helpers/validateFormsFields'
import useContactForm from '../hooks/useContactForm'
import postForm from '../helpers/postForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

export const ContactForm = ({ idForm, title, origin }) => {

  const [values, handleInputChange, reset] = useForm({})
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alertForm, setAlertForm] = useState(false)
  const [alertFormError, setAlertFormError] = useState(false)

  const dataContactForm = useContactForm()
  const allForms = dataContactForm?.allStrapiForms?.nodes

  const form = allForms.find(
    ({ strapiId }) => strapiId === idForm
  )

  const { name, email, phone, ...rest } = values

  const valuesRequired = {
    agent: form?.interest_receiver?.receiver,
    name,
    email,
    phone,
    subject: origin !== undefined ? `Consulta por: ${origin}` : "Consulta de Fundación Jean Sonet",
    type: form?.interest_type,
    content: rest
  }


  const bodyRequiredLength = form.bodyForm?.filter(
    (fields) => fields.required === true
  ).length

  useEffect(() => {
    if (
      values.name?.length > 2 &&
      validateEmail(values.email) &&
      validatePhoneNumber(values.phone) &&
      validateForm(
        valuesRequired.content,
        form?.bodyForm?.length,
        bodyRequiredLength
      )
    ) {
      return setValidated(true)
    } else {
      return setValidated(false)
    }
  }, [values])

  const handleSubmit = async (event) => {
    const formulario = event.currentTarget
    setLoading(true)
    event.preventDefault()

    try {
      const sendForm = await postForm(valuesRequired)

      if (sendForm.status) {
        setLoading(false)
        formulario.reset()
        reset()
        setAlertForm(true)
        setAlertFormError(false)
      } else {
        setLoading(false)
        setAlertForm(false)
        setAlertFormError(true)
      }
    } catch (error) {
      setLoading(false)
      setAlertForm(false)
      setAlertFormError(true)
    }
  }


  const handleClick = () => {
    setAlertForm(false)
  }

  const handleClickError = () => {
    setAlertFormError(false)
  }



  return (
    <div className="w-100 background-dark mb-5 py-3 position-relative">

      {alertForm &&
        <div className="form-alert container">
          <div className='send-ok'>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color={'green'}
            />
            <span>Pedido realizado con exito</span>
            <button className="button-form" onClick={handleClick}>OK</button>
          </div>
        </div>
      }

      {alertFormError &&
        <div className="form-alert container">
          <div className='send-error'>
            <FontAwesomeIcon
              icon={faExclamationCircle}
            />
            <span>Error al enviar el formulario, intente nuevamente más tarde!</span>
            <button className="button-form" onClick={handleClickError}>OK</button>
          </div>
        </div>
      }


      <div className="container text-white pt-4 pb-3">
        <h2 className="text-uppercase font-weight-extra-bold mb-3 pb-3">{title}</h2>
        <Form
          className="input-background-dark-form"
          noValidate
          onSubmit={handleSubmit}
          validated={validated}
          id="form"
        >
          <Row>
            <Col lg={4} xs={12}>
              <Form.Group controlId={'validationCustom'} className="mb-2">
                <Form.Label>{form?.name?.name + ' *'}</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  minLength="2"
                  autoComplete="off"
                  required
                  placeholder={form.name?.placeholder}
                  onChange={handleInputChange}
                  isValid={values.name?.length > 2}
                  isInvalid={
                    values.name?.length > 0 && values.name?.length <= 2
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Ingrese un dato por favor
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={4} xs={12}>
              <Form.Group controlId={'validationCustom'} className="mb-2">
                <Form.Label>{form.email?.name + ' *'}</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  autoComplete="off"
                  required
                  placeholder={form.email?.placeholder}
                  onChange={handleInputChange}
                  isValid={validateEmail(values.email)}
                  isInvalid={
                    values.email?.length > 0 && !validateEmail(values.email)
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Ingrese un mail válido por favor
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={4} xs={12}>
              <Form.Group controlId={'validationCustom'} className="mb-2">
                <Form.Label>{form.phone?.name + ' *'}</Form.Label>
                <Form.Control
                  name="phone"
                  type="number"
                  autoComplete="off"
                  required
                  placeholder={form.phone?.placeholder}
                  onChange={handleInputChange}
                  isValid={validatePhoneNumber(values.phone)}
                  isInvalid={
                    values.phone?.length > 2 &&
                    !validatePhoneNumber(values.phone)
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Ingrese un número válido por favor
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {form.bodyForm?.map((c, i) => {
              if (c.strapi_component === 'form.text') {
                return (
                  <Col lg={6} xs={12}>
                    <Form.Group
                      controlId={'validationCustom' + i}
                      className="mb-2"
                    >
                      <Form.Label>
                        {c.required ? c.name + ' *' : c.name + ''}
                      </Form.Label>
                      <Form.Control
                        name={!c.required ? '_' + c.name : c.name}
                        type="text"
                        autoComplete="off"
                        minLength={c.required ? 3 : 0}
                        required={c.required}
                        placeholder={c.placeholder}
                        onChange={handleInputChange}
                        isValid={
                          (c.required &&
                            valuesRequired.content[c.name] !== undefined &&
                            valuesRequired.content[c.name].length > 2) ||
                          (!c.required &&
                            valuesRequired.content['_' + c.name] !==
                            undefined &&
                            valuesRequired.content['_' + c.name].length > 2)
                        }
                        isInvalid={
                          c.required &&
                          valuesRequired.content[c.name]?.length > 0 &&
                          valuesRequired.content[c.name]?.length <= 2
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Ingrese un {c.name} por favor
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )
              } else if (c.strapi_component === 'form.select') {
                return (
                  <Col lg={12} xs={12}>
                    <Form.Group
                      controlId={'validationCustom' + i}
                      className="mb-2"
                    >
                      <Form.Label>
                        {c.required ? c.name + ' *' : c.name + ' (opcional)'}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name={c.name}
                        placeholder={c.placeholder}
                        onChange={handleInputChange}
                        required={c.required}
                        className="select-contact-form"
                        isValid={
                          c.required &&
                          valuesRequired.content[c.name] !== undefined
                        }
                      >
                        <option value="-1" selected disabled>
                          {c.placeholder}
                        </option>
                        {c.dropdownOptions.map((e, i) => (
                          <option value={e.option} key={'key_' + i + c.name}>
                            {e.option}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Seleccione {c.name} por favor
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )
              } else if (c.strapi_component === 'form.text-area') {
                return (
                  <Col lg={12} xs={12}>
                    <Form.Group
                      controlId={'validationCustom' + i}
                      className="mb-2"
                    >
                      <Form.Label>
                        {c.required ? c.name + ' *' : c.name + ''}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name={!c.required ? '_' + c.name : c.name}
                        required={c.required}
                        onChange={handleInputChange}
                        placeholder={c.placeholder}
                        style={{ height: '80px' }}
                        className="text-white"
                        minLength={c.required ? 3 : 0}
                        maxLength="300"
                        isValid={
                          (c.required &&
                            valuesRequired.content[c.name] !== undefined &&
                            valuesRequired.content[c.name]?.length > 2) ||
                          (!c.required &&
                            valuesRequired.content['_' + c.name] !==
                            undefined &&
                            valuesRequired.content['_' + c.name]?.length > 2)
                        }
                        isInvalid={
                          c.required &&
                          valuesRequired.content[c.name]?.length > 0 &&
                          valuesRequired.content[c.name]?.length <= 2
                        }
                      />
                    </Form.Group>
                  </Col>
                )
              } else if (c.strapi_component === 'form.checkbox') {
                return (
                  <Col lg={6} xs={12}>
                    <Form.Group className="mb-2">
                      <Form.Check
                        type="checkbox"
                        label={c.name}
                        name={'_' + c.name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                )
              }
            })}
          </Row>
          <Row>
            <Col lg={12} xs={12}>
              <Form.Group className="mb-2">
                <div className="d-flex justify-content-end align-items-center pt-2">
                  <div>
                    <button
                      className="rounded-pill btn btn-sm btn-info px-4"
                      type="submit"
                      style={{
                        cursor:
                          !validated || loading ? 'not-allowed' : 'pointer'
                      }}
                      disabled={!validated || loading}
                    >
                      {!loading ? (
                        <span className="px-2">Enviar</span>
                      ) : (
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="pr-2">Enviando</span>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

ContactForm.propTypes = {
  idForm: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  origin: PropTypes.string
}
