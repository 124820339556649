import { useStaticQuery, graphql } from 'gatsby'

const useHome = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiHomeFjs {
        nodes {
          body
          logo {
            imageDark {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageLight {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          navbar {
            typesOfTraining {
              training_types {
                title
                slug
              }
            }
            Pages {
              fjs_page {
                slug
                title
              }
            }
            ExternalLinks {
              url
              name
            }
          }
          interestArea {
            title
            interest_areas {
              slug
              title
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          bannerFJS {
            fjs_courses {
              name
              training_type
              slug
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          footerFJS {
            socialNetwork {
              id
              name
              url
            }
            ExternalList {
              name
              url
            }
            PageLists {
              id
              fjs_page {
                slug
                title
              }
            }
            buildingLocation {
              buildingName
              information
              url
            }
            logos {
              title
              url
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          seo {
            pageTitle
            pageDescription
            pageKeywords
          }
        }
      }
    }
  `)
  return query
}

export default useHome
