import { useStaticQuery, graphql } from 'gatsby'

const useContactForm = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiForms{
        nodes {
          strapiId
          nameForm
          uniqueReceiver
          interest_receiver {
            option
            receiver
          }
          interest_type {
            option
            type
          }
          name {
            name
            placeholder
          }
          email {
            name
            placeholder
          }
          phone {
            name
            placeholder
          }
          bodyForm
        }
      }
    }
  `)
  return query
}

export default useContactForm
