import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const PersonCard = ({ professional, columns }) => {

  return (
    professional && (
    <div className={`my-5 col-${columns}`}>
      <div className="shadow-sm bg-white professional-card rounded px-4 pt-4 pb-2 h-100 d-flex flex-column align-items-center">
        {professional.teacherDetails.image != null &&
          <img 
            src={professional.teacherDetails.image.url} 
            alt={professional.name}
            className="img-prof mb-3 bg-secondary"
          />
        }
        <h6>{professional.name}</h6>
        <h6>
          <small>{professional.role}</small>
        </h6>        
        <div className="pt-4 border-top mb-2 flex-grow-1 d-flex flex-column">
          <div className="pr-1 info-cont position-relative overflow-auto">
            <ReactMarkdown 
            // eslint-disable-next-line react/no-children-prop
            children={professional.teacherDetails.description} 
            className="publication-content-markdown"/>
          </div>
        </div>    
      </div>
    </div>      
    )
  )
}

export default PersonCard

PersonCard.propTypes = {
  professional: PropTypes.object.isRequired,
  columns: PropTypes.number
}
