import React from 'react'
import PropTypes from 'prop-types'

const CardAgreements = ({ agreements }) => {
  return (
    <div className='mb-5 container'>
      {agreements?.title && (
        <h2 className="text-uppercase font-weight-extra-bold ucc-text-blue mb-4">
          {agreements.title}
        </h2>
      )}
      <div className='row'>
        {agreements?.images?.map((agreement, idx) => {
          if (agreement?.visible) {
            return (
              <div className='col-12 col-sm-6 col-lg-3 mb-4' key={`agreement-${idx}`}>
                <div className='shadow-sm bg-white professional-card rounded px-4 pt-4 pb-2 h-100 d-flex flex-column align-items-center'>
                <div
                  role="img"
                  alt={agreement?.title}
                  className="img-prof mb-3 bg-secondary"
                  style={{ backgroundImage: `url(${agreement?.image?.url})` }}
                ></div>
                  <p className="text-center">
                    {agreement?.title}
                  </p>
                </div>
              </div>
            )
          }
        })}        
      </div>
    </div>
  )
}

export default CardAgreements

CardAgreements.propTypes = {
  agreements: PropTypes.object.isRequired,
}