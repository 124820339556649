import React from 'react'
import { CustomSections, BannerCarousel, Seo, AreasSection } from './'
import useHome from '../hooks/useHome'
// import PropTypes from 'prop-types'

const HomePage = () => {
  const dataHome = useHome()

  const sections = dataHome?.allStrapiHomeFjs?.nodes[0]?.body

  // Carousel
  const { allStrapiHomeFjs } = dataHome
  const fjs_courses = allStrapiHomeFjs?.nodes[0]?.bannerFJS?.fjs_courses

  //Seo
  const pageTitle = allStrapiHomeFjs?.nodes[0]?.seo?.pageTitle
  const pageDescription = allStrapiHomeFjs?.nodes[0]?.seo?.pageDescription
  const pageKeywords = allStrapiHomeFjs?.nodes[0]?.seo?.pageKeywords



  return (
    <>
      {pageTitle && pageDescription && pageKeywords && (
        <Seo
          title={pageTitle}
          description={pageDescription}
          keywords={pageKeywords}
        />
      )}
      <AreasSection /> 
      <BannerCarousel carouselData={fjs_courses} />
      {sections !== undefined && sections !== null && (
        <CustomSections sections={sections} />
      )}
    </>
  )
}

export default HomePage
