import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const Notice = ({ content }) => {
  return (
    <div className="w-100 background-dark mb-5 py-3">
      <div className="container text-white p-5 text-center">
        <ReactMarkdown
          // eslint-disable-next-line react/no-children-prop
          children={content}
          className="publication-content-markdown"
        />
      </div>
    </div>
  )
}

export default Notice

Notice.propTypes = {
  content: PropTypes.string
}
