import React, { createContext, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'

const themes = {
  light: {
    '--nav-primary': '#666666',
    '--nav-active': '#182c49',

    '--form-field-background': '#0e192a',

    '--body-title': '#182c49',

    '--primary': '#5e5e5e',
    '--primary-info': '#1d317d',
    '--primary-grey': '#666666',
    '--primary-container': '#ffffff',
    '--primary-container-dark': '#182c49',

    '--course-container': '#f8f9fa',

    '--secondary': '#ffffff',
    '--secondary-container': '#182c49',

    '--pagination': '#17a2b8',
    '--button-outline': '#17a2b8',
    '--button-outline-text': '#17a2b8',
    '--button-outline-text-hover': '#ffffff',
    '--tabs-active': '#00a4eb',
    '--tabs-inactive': '#86d2f3',
    '--tabs-inactive-text': '#ffffff',
    '--icon-outline': 'transparent',
    '--mark-down-link': '#1d317d'

  },
  dark: {
    '--nav-primary': '#cecece',
    '--nav-active': '#00a4eb',

    '--form-field-background': '#292929',

    '--body-title': '#ffffff',

    '--primary': '#ffffff',
    '--primary-info': '#0063aa',
    '--primary-grey': '#ffffff',
    '--primary-container': '#292929',
    '--primary-container-dark': '#ffffff14',

    '--course-container': '#292929',

    '--secondary': '#343a40',
    '--secondary-container': '#191919',

    '--pagination': '#ffffff',
    '--button-outline': '#00a8c3',
    '--button-outline-text': '#08d8f9',
    '--button-outline-text-hover': '#ffffff',
    '--tabs-active': '#00a4eb',
    '--tabs-inactive': '#7a7a7a',
    '--tabs-inactive-text': '#c7c7c7',
    '--icon-outline': '#00a4eb',
    '--mark-down-link': '#00A4EB'
    

  }
}

const ThemeContext = createContext(null)

// const { theme, setTheme, toggleTheme } = useTheme()
export const useTheme = () => useContext(ThemeContext)

// cambiar si cambia el tema por defecto
// const DEFAULT_THEME = 'light'

const ThemeProvider = ({ children }) => {
  const DEFAULT_THEME = 'light'
  const localTheme = typeof window !== 'undefined' ? localStorage.getItem('theme') : undefined
  const deviseTheme = getDeviseTheme()
  const [theme, setTheme] = useState(localTheme || deviseTheme || DEFAULT_THEME)

  // useEffect(() => {
  //   setTheme(localTheme || deviseTheme || DEFAULT_THEME)
  // }, [])

  useEffect(() => {
    localStorage.setItem('theme', theme)
  }, [theme])

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  return (
    <ThemeContext.Provider value={{ theme, setTheme, toggleTheme }}>
      <div style={{ ...themes[theme], background: 'var(--primary-container)' }}>{children}</div>
    </ThemeContext.Provider>
  )
}

const getDeviseTheme = () => {
  if (typeof window !== 'undefined') {
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark'
      } else {
        return 'light'
      }
    } else {
      return DEFAULT_THEME
    }
  } else {
    return undefined
  }
}
ThemeProvider.propTypes = {
  children: PropTypes.object
}

export default ThemeProvider
