import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Link } from 'gatsby'
import  useTrainingTypes from '../hooks/useTrainingTypes'
import useCourseTemplate from '../hooks/useCourseTemplate'
import PropTypes from 'prop-types'

const BannerCarousel = ({ carouselData }) => {
  const { allStrapiTrainingType: { nodes }} = useTrainingTypes()
  const training = nodes

  const dataCourseTemplate = useCourseTemplate()
  const {defaultCourseImg} = dataCourseTemplate?.strapiCourseFjsTemplate


  const slideData = carouselData?.map((slide, idx) => {
    const image = getImage(slide?.image?.localFile)
    const imgDefault = getImage(defaultCourseImg?.localFile)

    const bannerTitle = slide?.name

    // function capitalize(bannerTitle) {
    //   const lower = bannerTitle.toLowerCase()
    //   return bannerTitle.charAt(0).toUpperCase() + lower.slice(1)
    // }

    return (
      <Carousel.Item key={`item-${idx}`}>
        <div className="slide slide__Item">
          <BgImage image={image ? image : imgDefault} className="slide__bgImage">
            <div className="slide__content" style={{ minHeight: 465 }}>
              {training.map((name) => {
                if (name.strapiId === slide.training_type) {
                  return <p className="fs-6 fw-bolder">{name.title}</p>
                }
              })}
              <h2 className="mb-2 slide__title">{bannerTitle}</h2>
              <Link
                to={slide.slug}
                className="btn btn-sm btn-outline-light rounded-pill px-3"
              >
                <span>Ver más</span>
              </Link>
            </div>
          </BgImage>
        </div>
      </Carousel.Item>
    )

  })
  
  return (
    carouselData?.length > 0 && (
      <Carousel
        fade
        className="carousel custom-carousel d-flex flex-grow-1 h-100"
      >
          {slideData}   
      </Carousel>      
    )

  )
}

BannerCarousel.propTypes = {
  carouselData: PropTypes.array
}

export default BannerCarousel
