import { useStaticQuery, graphql } from 'gatsby'

const useCourses = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiFjsCourses(sort: { order: ASC, fields: dateStart }) {
        nodes {
          training_type {
            title
            slug
            id
          }
          featured
          tag {
            color
            title
          }
          strapiId
          slug
          name
          interest_areas {
            id
            title
            slug
            banner {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          id
          idSIUCC
          seo {
            pageKeywords
          }
          academic_unit {
            name
          }
          dateStartInscr(formatString: "DD MMM YYYY", locale: "ES-AR")
          dateStart
          dateStartQuery: dateStart(
            formatString: "DD MMM YYYY"
            locale: "ES-AR"
          )
        }
      }
    }
  `)
  return query
}

export default useCourses
