import React, { useState } from 'react'
import useHome from '../hooks/useHome'
import { Link } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'

const AreasSection = () => {
  const [showLinks, setShowLinks] = useState(false)

  const data = useHome()
  const title = data?.allStrapiHomeFjs?.nodes[0]?.interestArea?.title
  const interestArea = data?.allStrapiHomeFjs?.nodes[0]?.interestArea?.interest_areas

  const iconList = interestArea?.map((area, idx) => {
    const iconImage = getImage(area?.icon?.localFile)
    return (
      <div className="areas__item" key={`icon-${idx}`}>
        {area.title && (
          <Link to={'/' + area.slug} className="d-flex flex-column align-items-center text-white text-decoration-none">
            {iconImage && (
              <GatsbyImage
                image={iconImage}
                alt={area.title}
                className="mb-1"
              />
            )}
            {area.title}
          </Link>
        )}
      </div>
    )
  })

  return (
    <div className="w-100 background-dark areas areas__content" style={{ paddingTop: 10 }}>
      {title && (
        <h3 className="areas__title">{title}</h3>
      )}
      <button
        className="areas__button"
        onClick={() => setShowLinks(!showLinks)}
      >
        <FontAwesomeIcon className="mb-1 ml-1" icon={faSortDown} />
      </button>
      {iconList && (
        <div className={showLinks ? 'areas__section__mobile container' : 'areas__section container'}>{iconList}</div>
      )}
    </div>
  )
}

export default AreasSection
