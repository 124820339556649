import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const PdfPreview = ({ pdfData }) => {
  
  useEffect(() => {
    const pdfContainer = document.getElementById(
      `pdf-container-${pdfData.id}`
    )
    const codePdf = pdfData.code
      .replace('height:0', 'height:400px')
      .replace('max(60%', 'max(40%')
    pdfContainer.innerHTML = codePdf
  }, [pdfData.code])
  
  return (
    <div className="container mb-5">
      {pdfData?.title && (
        <h2 className={'text-uppercase font-weight-extra-bold mb-3 ucc-text-blue'}>
          {pdfData?.title}
        </h2>
      )}
      <div id={`pdf-container-${pdfData.id}`} />
    </div>
  )
} 

PdfPreview.propTypes = {
  pdfData: PropTypes.object,
}

export default PdfPreview