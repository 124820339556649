import React from 'react'
import PropTypes from 'prop-types'
import { getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import useCourses from '../hooks/useCourses'
import useCourseTemplate from '../hooks/useCourseTemplate'
import { Pagination, Card } from '.'

const CardsSection = ({ courses, title, related, areas, id }) => {
  const dataCourses = useCourses()
  const fjsCourses = dataCourses?.allStrapiFjsCourses?.nodes

  const dataCourseTemplate = useCourseTemplate()
  const { defaultCourseImg } = dataCourseTemplate?.strapiCourseFjsTemplate

  const coursesList = courses?.map((course, idx) => {
    const image = getImage(course?.image?.localFile)
    const imageDefault = getImage(defaultCourseImg?.localFile)
    return (
      <Card
        key={`course-${idx}`}
        course={course}
        related={related}
        image={image}
        imageDefault={imageDefault}
      />
    )
  })

  const latestCourses = fjsCourses
    .filter((course) => course.dateStart >= new Date().toISOString())
    .map((course, idx) => {
      const image = getImage(course?.image?.localFile)
      const imageDefault = getImage(defaultCourseImg?.localFile)
      return (
        <Card
          home={true}
          key={`course-${idx}`}
          course={course}
          related={related}
          image={image}
          imageDefault={imageDefault}
        />
      )
    })
    .splice(0, 8)

  const coursesToDisplay = courses ? coursesList : latestCourses

  return (
    <div className="container pb-3" id={id}>
      {/* temporary padding */}
      <div className="mb-5">
        <div className="d-flex align-items-center justify-content-between mb-3 novelties-title">
          <h2
            className={
              'text-uppercase font-weight-extra-bold mb-3 ucc-text-blue'
            }
          >
            {title}
          </h2>
          {!related && title && (
            <Link
              to="/oferta-academica"
              className={'btn btn-sm btn-outline-dark rounded-pill px-3 cards-section-button'}
            >
              Ver todos
            </Link>
          )}
        </div>
        {coursesToDisplay.length > 4 && related ? (
          <div className="row justify-content-center">
            <Pagination
              initialState={true}
              postPerPage="4"
              posts={coursesToDisplay}
              anchor={id}
            />
          </div>
        ) : null}
        {coursesToDisplay.length <= 4 && related ? (
          <div className="row">{coursesToDisplay}</div>
        ) : null}
        {coursesToDisplay.length > 12 && !related ? (
          <div className="row justify-content-center">
            <Pagination
              initialState={true}
              postPerPage="12"
              posts={coursesToDisplay}
              anchor={id}
            />
          </div>
        ) : null}
        {coursesToDisplay.length <= 12 && !related ? (
          <div className="row">
            {coursesToDisplay.length === 0 && areas === true && (
              <h6 className="px-5 primary-grey-font">No hay cursos próximos a comenzar en esta categoría.</h6>
            )}
            {coursesToDisplay}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CardsSection

CardsSection.propTypes = {
  courses: PropTypes.array,
  title: PropTypes.string,
  related: PropTypes.bool,
  areas: PropTypes.bool
}
