import { useStaticQuery, graphql } from 'gatsby'

const useTrainingTypes = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiTrainingType{
        nodes {
            title
            strapiId
            slug
        }
      }
    }
  `)
  return query
}

export default useTrainingTypes