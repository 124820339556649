import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import PropTypes from 'prop-types'

const CustomToggle = ({ children, eventKey }) => {

    const [activeStates, setActiveState] = useState(false)
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      setActiveState(!activeStates)
    })
    let classActive = activeStates ? 'active' : ''

    return (
      <button
        type="button"
        className={`btn ${classActive}`}
        onClick={decoratedOnClick}
      >
        {children}
        <FontAwesomeIcon icon={['fas', 'chevron-down']} size="sm" />
      </button>
    )
}

export default CustomToggle

CustomToggle.propTypes = {
    children: PropTypes.object.isRequired,
    eventKey: PropTypes.number.isRequired
  }
  

