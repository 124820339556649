import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

const Video = ({ videoComponent }) => {
    return (
        <div className="embed-responsive embed-responsive-16by9 mb-5 container">
          {videoComponent && (
            <ReactPlayer
              className="embed-responsive-item"
              url={`https://www.youtube.com/embed/${videoComponent.videoId}?rel=0`}
              controls={true}
              width="100%"
              height="100%"
            />
          )}
        </div>
      )
}

export default Video

Video.propTypes = {
    videoComponent: PropTypes.object.isRequired
}