import React from 'react'
import useHome from '../hooks/useHome'

const ButtonInscription = ({ dateEndInscr, link, linkContacto }) => {
    
    let dateEnd = new Date(dateEndInscr);

    const data = useHome()

    const ExternalList = data?.allStrapiHomeFjs?.nodes[0]?.footerFJS?.ExternalList
  
    const externalLink = ExternalList?.find((link) => {
      if (link.name === 'Whatsapp' || link.name === 'whatsapp') { 
        return (link.url)
      }})

    return (
<div className="mb-5 w-100 d-flex justify-content-center">
    {link && new Date() < dateEnd ? 
      (<a
        href={link}
        target="_blank"
        className="btn btn-outline-lighter rounded-pill px-3 "
        rel="noreferrer"
      >
      Inscribite
      </a>)
    : 
      ((linkContacto || (externalLink && externalLink.url != null)) &&
        (<a
          href={linkContacto || externalLink.url}
          target="_blank"
          className="btn btn-outline-lighter rounded-pill px-3"
          rel="noreferrer"
        >
          Contactate con Nosotros
        </a>)
          )    
        }
  </div>
    )
}
export default ButtonInscription