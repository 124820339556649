import React from 'react'
import PropTypes from 'prop-types'
import { 
  ContactForm, 
  TabsContent, 
  LogosSection, 
  TextBlock,
  PersonCard,
  CardsSection,
  Video,
  CardAgreements,
  PdfPreview,
 } from '.'
// import PropTypes from 'prop-types'

const CustomSections = ({ sections }) => {
  
  const section = sections.map((section, idx) => {

    return (
      <>
        {section.form !== null && section.form !== undefined ? (
          <ContactForm
            key={idx}
            idForm={section.form.id}
            title={section.title}
          />
        ) : null}

        {section.images !== null && section.images !== undefined && section.strapi_component !== 'fjs.multiple-images' ? (
          <LogosSection
            key={idx}
            images={section.images}
            title={section.title}
          />
        ) : null}

        {section.tabs !== null && section.tabs !== undefined && section.visible === true ? (
          <TabsContent key={idx} data={section.tabs} title={section.title} />
        ) : null}

        {section.strapi_component == 'general-content.text-content' && section.content !== undefined && section.content !== null && section.visible === true ? (
          <TextBlock key={idx} content={section.content} title={section.title} />
        ) : null}

        {section.strapi_component === 'fjs.teachers' && section.name !== undefined && section.name !== null ? (
          <PersonCard key={idx} professional={section} columns={12}/>
        ) : null}
        
        {section.strapi_component === 'fjs.courses-section' && section.visible == true ? (
          <div className="pt-5">
             <CardsSection title={section.title} id="fjs.courses-section"/>
          </div>
         
        ) : null}

        {section.strapi_component === 'pages-component.video' ? (
          <Video videoComponent={section.video} />
        ) : null}

        {section.strapi_component === 'pages-component.pdf' ? (
          <PdfPreview pdfData={section.pdf} />
        ) : null }

        {section.strapi_component === 'fjs.multiple-images' ? (
          <CardAgreements key={idx} agreements={section}/>
        ) : null }
      </>
    )
  })
  return <div>{section}</div>
}

CustomSections.propTypes = {
  sections: PropTypes.array,
}


export default CustomSections
